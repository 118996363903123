.collapsible {
  font-family: 'Press Start 2P';
  color: white;
  background-color: transparent;
  cursor: pointer;
  border: none;
  outline: none;
  font-size: 15px;
  display:block;
  margin-bottom: 10px;
}

.content {
  font-family: 'Press Start 2P';
  font-size: 15px;
  color: white;
  margin-bottom: 10px;
}

.option-list {
  columns: 5;
  cursor: pointer;
  margin-bottom: 10px;
}

.option {
  font-size: 10px;
  margin-bottom: 10px;
  font-family: 'Press Start 2P';
  color: white;
  background-color: transparent;
  cursor: pointer;
  border: none;
  outline: none;
  width: 150px;
}

.coloroption-list {
  width: 95%;
  background-color:rgba(192,192,192,0.8);
  cursor: pointer;
  padding-left: 5px;
}

.color-option {
  height: 40px;
  width: 50px;
  display:inline-block;
  margin-top: 5px;
  margin-bottom: 5px;
  cursor: pointer;
  border: none;
  outline: none;
}

.exportContainer {
  width: 100%;
  text-align: center;
}

.export {
  font-family: 'Press Start 2P';
  font-size: 15px;
  border: none;
  outline: none;
  padding: 10px;
  text-align:center;
  margin-top: 30px;
  cursor: pointer;
}

.export:hover {
  background-color: #e6e6e6;
}

@media screen and (max-width: 1100px) {
  .content {
    margin: 30px;
  }
  .option-list {
    columns: 4;
    cursor: pointer;
    margin-bottom: 10px;
  }
  .option {
    width: 80px;
  }
}
