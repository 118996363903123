.pixel-grid {
  height: 432px;
  width: 432px;
  display: grid;
  /* margin:auto; */
  grid-template-columns: auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto;
}

.pixel-grid-container {
  height: 432px;
  width: 432px;
  margin: auto;
}
.pixel {
  text-align: center;
  height: 12px;
  width: 12px;
  background-size: 12px 12px;
  margin: 0px;
  padding: 0px;
  grid-gap:0px;
  cursor: pointer;
}


@media screen and (max-width: 1150px) {
    .pixel-grid {
      margin: auto;
      height: 360px;
      width: 360px;
    	display: grid;
      padding-left: 0px;
    	grid-template-columns: auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto;
    }
    .pixel-grid-container {
      height: 360px;
      width: 360px;
      margin: auto;
    }
    .pixel {
      text-align: center;
      height: 10px;
      width: 10px;
      background-size: 10px 10px;
      margin: 0px;
      padding: 0px;
      grid-gap:0px;
      cursor: pointer;
    }
}

@media screen and (max-width: 800px) {
  .pixel-grid {
    margin: auto;
    height: 216px;
    width: 216px;
    display: grid;
    padding-left: 0px;
    grid-template-columns: auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto;
  }
  .pixel-grid-container {
    height: 216px;
    width: 216px;
    margin: auto;
  }
  .pixel {
    text-align: center;
    height: 6px;
    width: 6px;
    background-size: 2.5% 2.5%;
    margin: 0px;
    padding: 0px;
    grid-gap:0px;
    cursor: pointer;
  }
}
